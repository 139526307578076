import React from "react"
import { useFirebase } from "gatsby-plugin-firebase"

import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import Pricing from "../components/sections/pricing"
import Screenshots from "../components/sections/screenshots"
import Testimonials from "../components/sections/testimonials"

import categoryDrillDownVideo from "../videos/costco-parser.mp4"

const IndexPage = () => {

  useFirebase(firebase => { firebase.analytics().logEvent("page_view_index") }, [])

  return (
    <Layout>
      <SEO title="apocha" />
      <Navigation />
      <Header />
      <VideoContainer>
        <h4>See in action</h4>
        <VideoWrapper>
        <video width="100%" height="100%" preload='auto' loop autoPlay muted>
        <source src={categoryDrillDownVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
        <p>I want to <a href="https://apocha.app/receiptscanner" rel="noreferrer" target="_blank">try it out myself</a>!</p>
        </VideoWrapper>
      </VideoContainer>
      <SwiperContainer>
        <Screenshots/>
      </SwiperContainer>
      <Features/>
      <Testimonials />
      <Pricing />
      <GetStarted />
      <Footer />
    </Layout>
  )
}
export default IndexPage

const SwiperContainer = styled.div`
  margin: 0 auto;
  max-height: 50%;
  max-width: 50%;
  @media (max-width: ${props => props.theme.screen.md}) {
    max-height: 75%;
    max-width: 75%;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    max-height: 100%;
    max-width: 100%;
  }
  .slick-slider {
    margin: 0 auto;
    max-height: 50%;
    max-width: 50%;
    @media (max-width: ${props => props.theme.screen.md}) {
      max-height: 75%;
      max-width: 75%;
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
      max-height: 100%;
      max-width: 100%;
    }
  }
  
`

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 18px 40px;
  text-align: center;
`
const VideoWrapper = styled.div`
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) { width: 100% }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { width: 100% }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { width: 50% }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { width: 50% }
`
