import React from "react"

import styled from "styled-components"

import illustration_alphabet_abc from "../../images/illustrations/alphabet_abc.svg"
import illustration_chart from "../../images/illustrations/chart.svg"
import illustration_devices from "../../images/illustrations/devices.svg"
import illustration_hierarchy from "../../images/illustrations/hierarchy.svg"
import illustration_phone_with_picture from "../../images/illustrations/phone_with_picture.svg"
import illustration_search from "../../images/illustrations/search.svg"

import { Section, Container } from "../global"

const Features = () => {
  return (
  <Section id="features">
    <StyledContainer>
      <SectionTitle>Packed with features which help you to gain total control of your money</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Runs On Every Device</FeatureTitle>
          <FeatureText>
            apocha is a cloud based web application which runs in the browser on all your devices.
            You can take a picture of your receipt with your phone in the store and do the analyses and planning
            at home with your phone, tablet, or notebook.
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_devices} alt="Illustration with mobile phone, tablet, and computer"/>
          </FeatureImageContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Automatic Receipt Import</FeatureTitle>
          <FeatureText>
            You can manually enter your income and expense transactions, of course. But it's much
            simpler to let apocha do the work. apocha will import and categorize your expenses
            at the item level. No more endless manual split calculations of your long supermarket
            receipts. And all that simply by taking a picture with your phone.
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_phone_with_picture} alt="Illustration with pictur in phone"/>
          </FeatureImageContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>5000+ Categories</FeatureTitle>
          <FeatureText>
            For its automatic item categorization apocha selects from over 5000 predefined categories.
            Of course you can change the selected category if it does not really fit your choice.
            In addition, you can add custom tags for your own additional classifications.
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_alphabet_abc} alt="Illustration with abc blocks "/>
          </FeatureImageContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Transaction List</FeatureTitle>
          <FeatureText>
            You can look at your full transaction list, narrow it down to a certain date interval,
            limit it to transactions in a selected account,
            or do a full text search. That way you find receipts and items with ease,
            you can track product
            prices, and search for items reaching the end of their warranty.
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_search} alt="Illustration with "/>
          </FeatureImageContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Virtual Subaccounts</FeatureTitle>
          <FeatureText>
            Even with only one real bank account you can budget and track your expenses in virtual subaccounts.
            If you are saving for several things in one bank account you can add virtual subaccount
            for e.g. vacation, a new phone, or whatever you want to buy in the future.  
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_hierarchy} alt="Illustration with a hierarchy"/>
          </FeatureImageContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Charts, Charts, Charts</FeatureTitle>
          <FeatureText>
            apocha offers a vast number of charts for your analyses: bar charts, column charts,
            pie charts for transactions and expense categories with multi level drill downs, 
            a map of your shopping locations, and so on. Heat maps, Sankey charts and many more will be
            added soon.  
          </FeatureText>
          <FeatureImageContainer>
            <img src={illustration_chart} alt="Illustration with "/>
          </FeatureImageContainer>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
`

const SectionTitle = styled.h4`
  font-size: ${props => props.theme.font_size.large};
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 960px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  padding: 10px 50px;
  box-shadow: 0px 0px 6px 0px rgba(57,58,61,.15);
  border-radius: 8px;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 10px 25px;
  }

`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const FeatureImageContainer = styled.div`
  margin-top: auto;
  width: 300px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 250px;
  }
`