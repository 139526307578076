import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container, Section } from "../global"
import styled from "styled-components"

const Screenshots = () => {
  var settings = {
	autoplay: true,
	autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const data = useStaticQuery(graphql`
    query {
      accounts: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_accounts" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      chart1: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_chart_1" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      chart2: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_chart_2" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      map: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_map" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      receiptDetails: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_receipt_details" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      settings: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_settings" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      transactionList: file(sourceInstanceName: { eq: "product" }, name: { eq: "swiper_apocha_transaction_list" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
	<StyledSection>
		<ScreenshotContainer>
			<Slider {...settings}>
			<div>
				<StyledImage loading="eager" fluid={data.transactionList.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - transaction list"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.receiptDetails.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - receipt details"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.chart1.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - charts"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.chart2.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - charts"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.accounts.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - accounts"/>
			</div>
			<div>
				<StyledImage loading="eager" fluid={data.map.childImageSharp.fluid}
				alt="Screenshot of the apocha expense tracker - map"/>
			</div>
			</Slider>
		</ScreenshotContainer>
	</StyledSection>
  );
}

export default Screenshots

const StyledSection = styled(Section)`
`

const ScreenshotContainer = styled(Container)`
`

const StyledImage = styled(Img)`
  margin-top: -25px;
`
