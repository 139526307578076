import React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container, Section } from "../global"
import styled from "styled-components"

const Testimonials = () => {
  var settings = {
	autoplay: true,
	autoplaySpeed: 10000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
	<StyledSection>
		<TestimonialContainer>
			<h4>What users say</h4>
			<Slider {...settings}>
			<div class="testimonial">
				<Quote>
					The cost tracing is just exaggeratedly awesome.
				</Quote>
				<div>
					BarbourBoris on reddit
				</div>
			</div>
			<div class="testimonial">
				<Quote>
					I never thought that keeping a budget book could be so easy.
				</Quote>
				<div>
					Martin L. in an email to support@apocha.app
				</div>
			</div>
			<div class="testimonial">
				<Quote>
					I have searched in vain for this for a long time.
				</Quote>
				<div>
					Sabine G. on facebook
				</div>
			</div>
			</Slider>
		</TestimonialContainer>
	</StyledSection>
  );
}

export default Testimonials

const StyledSection = styled(Section)`
`

const TestimonialContainer = styled(Container)`
  padding: 0px 20px 40px;
  text-align: center;
  max-width: 480px;
`

const Quote = styled.h4`
	font-size: 1.41em
`
