import React from "react"

import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "website_header" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Heading>
				      The Intelligent<br/>
              Expense Manager
            </Heading>
            <Subheading>
            	You've spent hours writing down and categorizing your expenses and doing manual split calculations.
              apocha's intelligent receipt scanner takes care of this time-consuming and tedious work for you.
              Now you'll do your expense tracking simply by taking a picture of your receipt.
            </Subheading>
          </HeaderTextGroup>
          <Text>
            <StyledImage loading="eager" fluid={data.file.childImageSharp.fluid} />
          </Text>
          <HeaderForm onSubmit={event => {
            event.preventDefault();
            window.location.href="https://apocha.app/signin";
            }}>
            <HeaderButton>
              Start now! It's free.
            </HeaderButton>
          </HeaderForm>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 80px 0 0px 0;
  }
`
const Heading = styled.h1`
  ${props => props.theme.font_size.xlarge};
  color: ${props => props.theme.color.primary};
	font-weight: bold;
	line-height: 56px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    ${props => props.theme.font_size.larger};
  	font-weight: normal;
  }
`

const Subheading = styled.p`
  ${props => props.theme.font_size.regular}
  color: ${props => props.theme.color.primary};
  line-height: 1.2em;
  margin-bottom: 12px;
	text-align: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    ${props => props.theme.font_size.default};
  	font-weight: normal;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }
`
const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
	justify-content: center;
  padding-bottom: 16px;
	text-align: center;
	
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    padding-bottom: 0px;
  }
`

const HeaderButton = styled.button`
  font-weight: bold;
  font-size: 20px;
  color: white;
  height: 60px;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 8px;
  padding: 0px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  min-width: 320px;
  margin-bottom: 50px;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Text = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
	margin-top: -25px;
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`
