import React from "react"

import styled from "styled-components"

import { Container, Section } from "../global"

const Pricing = () => (
  <StyledSection>
    <PricingContainer>
      <PricingTitle>Pricing</PricingTitle>
      <SubscriptionContainer>
        <PlanContainer>
          <PlanHeader>
            <h4>Standard</h4>
            <h5>free</h5>
            <p>no subscription</p>
          </PlanHeader>
          <FeatureList>
            <li>10 receipt imports / month</li>
            <li>5 accounts (bank, credit card, ...)</li>
            <li>&nbsp;</li>
            <li>WebApp running in the browser</li>
            <li>Virtual sub-accounts</li>
            <li>Many currencies</li>
            <li>Manual receipt input</li>
            <li>Receipt templates</li>
            <li>Automatic receipt import</li>
            <li>Import von CSV, PDF, Fotos</li>
            <li>Receipt export as CSV/Excel</li>
            <li>Split transactions</li>
            <li>5500+ pre-defined categories</li>
            <li>Customized tags</li>
            <li>Full text search in receipts</li>
            <li>Charts, charts, charts</li>
            <HeaderForm onSubmit={event => {
              event.preventDefault();
              window.location.href="https://apocha.app/";
              }}>
             	<HeaderButton>Start for free</HeaderButton>
            </HeaderForm>
          </FeatureList>
        </PlanContainer>
        <PlanContainer>
          <PlanHeader>
            <h4>Plus</h4>
            <h5>$40 billed yearly</h5>
            <p>or $4.50 billed monthly</p>
          </PlanHeader>
          <FeatureList>
            <li>50 receipt imports / month</li>
            <li>50 financial accounts</li>
            <li>&nbsp;</li>
            <li>WebApp running in the browser</li>
            <li>Virtual sub-accounts</li>
            <li>Many currencies</li>
            <li>Manual receipt input</li>
            <li>Receipt templates</li>
            <li>Automatic receipt import</li>
            <li>Import von CSV, PDF, Fotos</li>
            <li>Receipt export as CSV/Excel</li>
            <li>Split transactions</li>
            <li>5500+ pre-defined categories</li>
            <li>Customized tags</li>
            <li>Full text search in receipts</li>
            <li>Charts, charts, charts</li>
            <HeaderForm onSubmit={event => {
              event.preventDefault();
              window.location.href="https://apocha.app/";
              }}>
             	<HeaderButton>Try for free</HeaderButton>
            </HeaderForm>
          </FeatureList>
        </PlanContainer>
        <PlanContainer>
          <PlanHeader>
            <h4>Premium</h4>
            <h5>$60 billed yearly</h5>
            <p>or $8 billed monthly</p>
          </PlanHeader>
          <FeatureList>
            <li>250 receipt imports / month</li>
            <li>Unlimited financial accounts</li>
            <li>&nbsp;</li>
            <li>WebApp running in the browser</li>
            <li>Virtual sub-accounts</li>
            <li>Many currencies</li>
            <li>Manual receipt input</li>
            <li>Receipt templates</li>
            <li>Automatic receipt import</li>
            <li>Import von CSV, PDF, Fotos</li>
            <li>Receipt export as CSV/Excel</li>
            <li>Split transactions</li>
            <li>5500+ pre-defined categories</li>
            <li>Customized tags</li>
            <li>Full text search in receipts</li>
            <li>Charts, charts, charts</li>
            <HeaderForm onSubmit={event => {
              event.preventDefault();
              window.location.href="https://apocha.app/";
              }}>
             	<HeaderButton>Try for free</HeaderButton>
            </HeaderForm>
          </FeatureList>
        </PlanContainer>
      </SubscriptionContainer>
    </PricingContainer>
  </StyledSection>
)

export default Pricing

const StyledSection = styled(Section)`
`

const PricingContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
  max-width: 760px;
`

const PricingTitle = styled.h4`
  margin: 0 auto 32px;
  text-align: center;
`

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 960px;
  width: 100%;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    padding: 0 16px;
  }
`

const PlanContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border-radius: 1.0em;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(100,100,100,0.85);
  -moz-box-shadow: 0px 0px 2px 0px rgba(100,100,100,0.85);
  box-shadow: 0px 0px 2px 0px rgba(100,100,100,0.85);
  padding-bottom: 1.5rem;
  margin: 30px 15px;
  width: 80%;
`

const PlanHeader = styled.div`
  background-color: #1676f3;
  border-top-left-radius: 1.0em;
  border-top-right-radius: 1.0em;
  margin-block-start: 0;
  margin-block-end: 0;
  color: white;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  text-align: center;
  h4 {
    margin-block-end: 0;
  }
  h5 {
    font-size: 22px;
    margin-block-start: 10px;
    margin-block-end: 10px;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`

const FeatureList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  li {
    margin: 15px auto;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
	justify-content: center;
  padding-bottom: 16px;
	text-align: center;
	margin: 50px 0px 25px 0px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    padding-bottom: 0px;
  }
`

const HeaderButton = styled.button`
  font-weight: bold;
  font-size: 20px;
  color: white;
  height: 2em;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 8px;
  padding: 0px 0px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  width: 100%;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
